import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import  { Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Tappy from '../images/projects/tappy-2.png';
import MovieRater from '../images/projects/movie-rater.png';
import SEProject from '../images/projects/music-box.jpg';
import PersonalPortfolio from '../images/projects/personal-portfolio.png';
import ReactPlayer from "react-player";
import {FaArrowLeft,FaArrowRight} from 'react-icons/fa';

const Projects = () => {
  const swiperRef = React.useRef(null);


  return (
    <div class='card-projects' id='Projects'>
      <button id="previousButton" onClick={() => swiperRef.current.swiper.slidePrev()}><FaArrowLeft size={20}/></button>
      <button id="nextButton" onClick={() => swiperRef.current.swiper.slideNext()}><FaArrowRight size={20}/></button>
      <Swiper
        ref={swiperRef}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >

        <SwiperSlide>
          <p><h2>1.Personal Portfolio</h2>Current webpage with backed and frontend attributes.<br></br>
            <a href="https://github.com/Talebi-T9630/personal-portfolio">Github: Personal Portfolio!</a></p>
          <img src={PersonalPortfolio} ></img>
        </SwiperSlide>
        <SwiperSlide>
          <p><h2>2.Movie Rater</h2>FullStack movie rating website.<br></br>
            <a href="https://github.com/Talebi-T9630/MovieRater">Github: Movie Rater!</a></p>
          <img src={MovieRater}></img>

        </SwiperSlide>
        <SwiperSlide>
          <p><h2>3.Tappy</h2>Android mini games project.<br></br>
            <a href="https://github.com/Talebi-T9630/TappyForAndroid">Github: TAPPY!</a></p>
          <img src={Tappy} style={{ width: '80%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} ></img>
        </SwiperSlide>
        <SwiperSlide><p><h2>4.Portable Studio</h2>A mapped out skeleton of an original software following Software Engineering concepts.<br></br>
          <a href="https://github.com/Talebi-T9630/mySEJavaProject">Github: Software Engineering Mock Project!</a></p>
          <img src={SEProject} style={{ width: '80%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}></img>
        </SwiperSlide>

        {/* <SwiperSlide><p><h2>5.Video Tutorial</h2>Animated video tutorial for course registration to display on the official website of Douglas College.<br></br>
        </p> */}
        {/* Fix responsiveness */}
        {/* <div class='video-tutorial'>
            <ReactPlayer
              className="video-responsive"
              url={`https://youtu.be/SJI14DaDxgo`}
              playing={false}
              width="853"
              height="400"
            />
          </div>
        </SwiperSlide> */}



      </Swiper>
    </div>
  );
}

export default Projects;